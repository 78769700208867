import {
	IcoArrowLeft,
	IcoArrowRight,
	IcoCloseModal,
	IcoCoupon,
	IcoGift,
	IcoLogout,
	IcoMenu
} from "assets/icons";
import MinimalistLogo from "assets/images/vainaboa-logo-minimalist";
import clsx from "clsx";
import colors from "colors";
import AuthTokenHelper from "helpers/AuthTokenHelper";
import { usePartnerSectionContext } from "hooks/usePartnerSectionContext";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MenuItem from "./modules/MenuItem";
import PartnerSectionProfile from "./modules/PartnerSectionProfile";
import "./styles.scss";

const sidebarData = [
	{
		title: "Validação",
		icon: (isSelected: boolean) => (
			<IcoCoupon
				color={
					isSelected
						? colors["charts-grape"]["700"]
						: colors.neutral["low-pure-500"]
				}
			/>
		),
		path: (partnerRegisterId: string) => `/area-parceiro/${partnerRegisterId}`
	},
	{
		title: "Benefícios",
		icon: (isSelected: boolean) => (
			<IcoGift
				color={
					isSelected
						? colors["charts-grape"]["700"]
						: colors.neutral["low-pure-500"]
				}
			/>
		),
		path: (partnerRegisterId: string) =>
			`/area-parceiro/${partnerRegisterId}/beneficios`
	}
];

function SidebarPartnerSection() {
	const location = useLocation();
	const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
	const [isMobileSidemenuVisible, setIsMobileSidemenuVisible] = useState(false);
	const { partnerRegisterId } = useParams();

	const [selectedMenuItem, setSelectedMenuItem] = useState<number>();

	const [isSidebarRetracted, setIsSidebarRetracted] = useState(false);

	const { partnerDetails } = usePartnerSectionContext();

	const navigate = useNavigate();

	const handleLogout = async () => {
		AuthTokenHelper.removeAuthToken();
		navigate("/");
	};

	useEffect(() => {
		const currentPath = location.pathname;
		let currentIndex = 0;

		if (currentPath.includes("/beneficios")) {
			currentIndex = 1;
		}

		setSelectedMenuItem(currentIndex);
	}, [location.pathname]);

	return (
		<div
			className={clsx("sidebar-partner-section", {
				retracted: isSidebarRetracted,
				mobile: isMobile,
				"mobile-sidemenu-visible": isMobile && isMobileSidemenuVisible
			})}
		>
			{isMobile && (
				<div className="mobile-menu">
					<div
						className="hamburguer-icon"
						onClick={() => setIsMobileSidemenuVisible(true)}
					>
						<IcoMenu color={colors.neutral["low-pure-500"]} size="24" />
					</div>
					<p className="client-name">{partnerDetails?.name}</p>
				</div>
			)}
			<div className="content">
				<div className="header">
					<MinimalistLogo size="40" />
					{isMobile && (
						<div
							className="close-button"
							onClick={() => setIsMobileSidemenuVisible(false)}
						>
							<IcoCloseModal color={colors.neutral["low-pure-500"]} />
						</div>
					)}
				</div>
				<p className="client-name">{partnerDetails?.name}</p>
				<div className="menu-items">
					{sidebarData.map((item, index) => (
						<MenuItem
							key={index}
							title={item.title}
							icon={item.icon(
								location.pathname === item.path(partnerRegisterId!)
							)}
							to={item.path(partnerRegisterId!)}
							isSelected={index === selectedMenuItem}
							isRetracted={isSidebarRetracted}
						/>
					))}
				</div>
				<div className="footer">
					<div className="profile" onClick={() => setIsSidebarRetracted(false)}>
						<PartnerSectionProfile
							isRetracted={isSidebarRetracted}
							partnerResponsibleName={partnerDetails?.responsibleName}
						/>
						{isMobile && (
							<div className="logout-button" onClick={handleLogout}>
								<IcoLogout />
							</div>
						)}
					</div>
					{!isMobile && !isSidebarRetracted && (
						<button
							className="retract-sidebar-button"
							onClick={() => setIsSidebarRetracted(true)}
						>
							<IcoArrowLeft size="24" color={colors.neutral["low-pure-500"]} />
							<p className="font-body-regular-small">Diminuir menu</p>
						</button>
					)}
					{!isMobile && isSidebarRetracted && (
						<button
							className="retract-sidebar-button"
							onClick={() => setIsSidebarRetracted(false)}
						>
							<IcoArrowRight size="24" color={colors.neutral["low-pure-500"]} />
						</button>
					)}
				</div>
			</div>
		</div>
	);
}

export default SidebarPartnerSection;
