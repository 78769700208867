import { CookieHelper, ErrorHelper } from "helpers";
import AuthTokenHelper from "helpers/AuthTokenHelper";
import { User } from "models/auth/User";
import { toast } from "react-toastify";
import { getB2cLoginUrl } from "utils";
import { BaseServices } from "./baseServices";

export class ExternalAuthService extends BaseServices<User> {
	constructor() {
		super(User);
	}

	async loginB2C(partnerRegisterId?: string | undefined) {
		if (partnerRegisterId) {
			AuthTokenHelper.setCookie(partnerRegisterId, "partner_register_id");
		} else CookieHelper.setCookies("is_partner_b2c_login", true, 0.1);

		AuthTokenHelper.removeAuthToken();

		const loginURL = getB2cLoginUrl();

		window.location.assign(loginURL);
	}

	async logout() {
		return this.get("auth/logout/").catch((error) =>
			toast.error(ErrorHelper.getResponseErrorMessage(error))
		);
	}

	async verifyUser(partnerRegisterId: string | undefined) {
		return super.postWithResponse("user", {}, { partnerRegisterId });
	}
}
