import { BaseModel } from "./BaseModel";
import { CityProps, PartnerAddressProps } from "./types";

export class PartnerAddress extends BaseModel {
	postalCode: string;
	street: string;
	number: string;
	complement: string;
	neighbourhood: string;
	city: CityProps;

	constructor(data: PartnerAddressProps) {
		super(data);
		this.postalCode = data.postalCode;
		this.street = data.street;
		this.number = data.number;
		this.complement = data.complement;
		this.neighbourhood = data.neighbourhood;
		this.city = data.city;
	}
}
