import { IcoCloseModal, IcoSearch } from "assets/icons";
import clsx from "clsx";
import Button from "components/Button";
import { ChangeEvent, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { SearchBarProps } from "./types";

import "./styles.scss";

function SearchBar({ onSearch, placeholder = "Busca" }: SearchBarProps) {
	const [searchText, setSearchText] = useState("");
	const [isFocused, setIsFocused] = useState(false);
	const [isSearchButtonVisible, setIsSearchButtonVisible] = useState(false);
	const [recentSearches, setRecentSearches] = useState<string[]>([]);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
		null
	);
	const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

	const inputRef = useRef<HTMLInputElement>(null);

	const submitSearch = (text: string) => {
		onSearch(text);
		if (text.trim() !== "") {
			setRecentSearches((prevSearches) => [text, ...prevSearches.slice(0, 4)]);
		}
	};

	const searchHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const { target } = event;
		setSearchText(target.value);
		setSelectedIndex(-1);

		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}

		setTypingTimeout(
			setTimeout(() => {
				submitSearch(target.value);
			}, 500)
		);
	};

	const handleFocus = () => {
		setIsFocused(true);
		setIsSearchButtonVisible(true);
	};

	const handleBlur = () => {
		setIsFocused(false);
		setSelectedIndex(-1);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			if (selectedIndex >= 0 && selectedIndex < recentSearches.length) {
				setSearchText(recentSearches[selectedIndex]);
				submitSearch(recentSearches[selectedIndex]);
			} else {
				submitSearch(searchText);
			}
			setSelectedIndex(-1);
		} else if (event.key === "ArrowDown") {
			event.preventDefault();
			setSelectedIndex((prevIndex) =>
				prevIndex < recentSearches.length - 1 ? prevIndex + 1 : 0
			);
		} else if (event.key === "ArrowUp") {
			event.preventDefault();
			setSelectedIndex((prevIndex) =>
				prevIndex > 0 ? prevIndex - 1 : recentSearches.length - 1
			);
		}
	};

	const handleClearSearches = () => {
		setRecentSearches([]);
	};

	const handleClearSingleSearch = (index: number) => {
		setRecentSearches((prevSearches) =>
			prevSearches.filter((_, i) => i !== index)
		);
	};

	return (
		<div
			className={clsx("search-bar-component", {
				mobile: isMobile
			})}
		>
			<div className="search-bar">
				<input
					type="search"
					name="search"
					placeholder={placeholder}
					value={searchText}
					onChange={searchHandler}
					onKeyDown={handleKeyDown}
					onFocus={handleFocus}
					onBlur={handleBlur}
					autoComplete="off"
					ref={inputRef}
				/>
				{searchText.length > 0 ? (
					<div
						className="cursor-pointer"
						onClick={() => {
							setSearchText("");
							onSearch("");
							if (isMobile && inputRef.current) {
								inputRef.current.focus();
								handleFocus();
							}
						}}
					>
						<IcoCloseModal size="16" />
					</div>
				) : (
					<IcoSearch size="24" />
				)}
			</div>
			{isFocused && recentSearches.length > 0 && searchText.length === 0 && (
				<div
					className="recent-searches"
					onMouseDown={(e) => e.preventDefault()}
				>
					<div className="header">
						<h5 className="font-body-semibold-small text-[#8C8E91]">
							Buscas recentes
						</h5>
						<button
							className="font-body-regular-small underline"
							onClick={handleClearSearches}
						>
							Limpar
						</button>
					</div>
					{recentSearches.map((search, index) => (
						<div
							key={index}
							className={clsx("search", {
								selected: selectedIndex === index
							})}
							onMouseEnter={() => setSelectedIndex(index)}
						>
							<div
								key={index}
								className="font-body-regular-small truncate w-full"
								onClick={() => {
									setSearchText(search);
								}}
							>
								{search}
							</div>
							<div onClick={() => handleClearSingleSearch(index)}>
								<IcoCloseModal size="16" />
							</div>
						</div>
					))}
				</div>
			)}
			{isMobile && isSearchButtonVisible && (
				<div className="search-button">
					<Button
						styled="primary"
						onClick={() => {
							submitSearch(searchText);
							setIsSearchButtonVisible(false);
						}}
						cssClass="font-body-semibold-medium w-full"
						disabled={searchText.length === 0}
					>
						Buscar
					</Button>
				</div>
			)}
		</div>
	);
}

export default SearchBar;
