import { IcoCheckFilled, IcoErrorFilled } from "assets/icons";
import { useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import { toast, ToastPosition } from "react-toastify";

import "./toastifyCustomStyles.scss";

interface ToastReturn {
	showSuccessToast: (
		message?: string,
		title?: string,
		position?: ToastPosition
	) => void;
	showErrorToast: (
		message?: string,
		title?: string,
		position?: ToastPosition
	) => void;
}

const usePartnerSectionToast = (): ToastReturn => {
	const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
	const toastDefaultPosition: ToastPosition = isMobile
		? "bottom-center"
		: "top-center";

	const showErrorToast = useCallback(
		(message?: string, title = "Erro", position?: ToastPosition) =>
			toast.dark(
				<div className="flex flex-col gap-1">
					<div className="flex flex-row items-center gap-2">
						<IcoErrorFilled size="24" />
						<h1 className="font-body-semibold-medium">{title}</h1>
					</div>
					<p className="font-body-regular-small">{message}</p>
				</div>,
				{
					position: position || toastDefaultPosition,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					theme: "dark",
					progressClassName: "progress-bar-custom-error",
					closeButton: true
				}
			),
		[toastDefaultPosition]
	);

	const showSuccessToast = useCallback(
		(message?: string, title = "Sucesso!", position?: ToastPosition) =>
			toast.dark(
				<div className="flex flex-col gap-1">
					<div className="flex flex-row items-center gap-2">
						<IcoCheckFilled size="24" />
						<h1 className="font-body-semibold-medium">{title}</h1>
					</div>
					<p className="font-body-regular-small">{message}</p>
				</div>,
				{
					position: position || toastDefaultPosition,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					theme: "dark",
					progressClassName: "progress-bar-custom-sucess",
					closeButton: true
				}
			),
		[toastDefaultPosition]
	);

	return {
		showSuccessToast,
		showErrorToast
	};
};

export default usePartnerSectionToast;
