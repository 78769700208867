import { WorkingHoursData } from "components/PartnerForms/steps/types";
import { BenefitInterface } from "pages/backoffice/Partners/Benefit/types";

export interface Benefit {
	id: string;
	name: string;
	image: string;
	location: { name: string; id: string };
	description: string;
	categories: { name: string; id: string }[];
	status?: "ACTIVE" | "INACTIVE";
}

export interface BenefitProps extends BenefitInterface {
	id: string;
	description: string;
	image: string;
	partner: {
		name: string;
		description: string;
		addresses: {
			city: string;
			complement?: string;
			neighbourhood: string;
			number: string;
			postalCode: string;
			state: string;
			street: string;
		}[];
		categories: { name: string; id: string }[];
	};
	status: "ACTIVE" | "INACTIVE";
}

export interface CategoryProps {
	id: string;
	name: string;
}

export interface CityProps {
	id: string;
	name: string;
	description: string;
	image: string;
	state: string;
}

export interface Location {
	id: string;
	name: string;
	description: string;
	image: string;
	state: string;
}

export interface LocationProps {
	id: string;
	name: string;
	description: string;
	image: string;
	state: string;
}

export interface PartnerRegisterProps {
	partnerId: string;
	prospectId: string;
	name: string;
	term: string;
	termUrl: string;
}

export interface PartnerAddressProps {
	postalCode: string;
	street: string;
	number: string;
	complement: string;
	neighbourhood: string;
	city: CityProps;
}

export interface PartnerResponsible {
	responsibleName: string;
	responsiblePhone: string;
	responsibleEmail: string;
	responsibleCpf?: string;
	responsibleRole?: string;
}

export interface WorkingHoursProps {
	dayOfWeek: string;
	startHour: string;
	endHour: string;
}

export interface PartnerApplication extends PartnerResponsible {
	comment: string;
	addressPostalCode: string;
	addressStreet: string;
	addressNumber: string;
	addressComplement?: string;
	addressNeighbourhood: string;
	addressCity: string | null;
	addressState: string;
}

export interface PartnerInterface extends PartnerResponsible {
	id?: string;
	name: string;
	cnpj: string;
	contactFacebook: string;
	contactInstagram: string;
	contactPhone: string;
	contactWhatsapp: string;
	description: string;
	legalName: string;
	logoImage: string;
	establishmentImage: string;
	siteUrl: string;
	status?: string;
	statusRegister?: string;
	partnerRegisterId?: string;
	tempRegistrationIsCompleted: boolean;
	tenantId: string;
	term: string;
	tradeName: string;
	ie: string;
	remark: string;
	categoryIds: string[];
	categories?: CategoryProps[];
	addresses?: PartnerAddressProps[];
	address?: PartnerAddressProps;
	workingHours: WorkingHoursData;
	prospectId?: string;
}

export interface Category {
	id: string;
	name: string;
}

export enum StatusTypes {
	active = "ACTIVE",
	inactive = "INACTIVE"
}

export enum StatusRegisterTypes {
	created = "CREATED",
	pendingData = "PENDING_DATA",
	pendingEmail = "PENDING_EMAIL",
	pendingAcceptTerm = "PENDING_ACCEPT_TERM",
	pendingBenefit = "PENDING_BENEFIT",
	pendingApprove = "PENDING_APPROVE",
	approved = "APPROVED",
	deactivated = "DEACTIVATED"
}

export interface URLSortingFilterType {
	name: string;
	description: string;
	urlFilter: string;
}
