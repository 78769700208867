import { BaseModel } from "./BaseModel";
import { CategoryProps } from "./types";

export class Category extends BaseModel {
	id: string;
	name: string;

	constructor(data: CategoryProps) {
		super(data);
		this.id = data.id;
		this.name = data.name;
	}
}
