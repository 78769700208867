import clsx from "clsx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, Button } from "components";

import colors from "colors";
import AuthTokenHelper from "helpers/AuthTokenHelper";
import "./styles.scss";

export default function PartnerSectionProfile({
	onClose,
	isRetracted = false,
	partnerResponsibleName
}: {
	onClose?: () => void;
	isRetracted?: boolean;
	partnerResponsibleName?: string;
}) {
	const [toggleMenuSupport, setToggleMenuSupport] = useState<boolean>(false);
	const navigate = useNavigate();

	const handleLogout = async () => {
		AuthTokenHelper.removeAuthToken();
		navigate("/");
		if (onClose) {
			onClose();
		}
	};

	return (
		<div
			className={clsx("wrapper-profile-partner-section", {
				open: toggleMenuSupport,
				retracted: isRetracted
			})}
		>
			<div
				className="profile-name-wrapper"
				onClick={() => setToggleMenuSupport(!toggleMenuSupport)}
			>
				<Avatar size="lg" borderColor={colors.neutral["high-400"]}>
					{partnerResponsibleName?.charAt(0)}
				</Avatar>
				{!isRetracted && (
					<p className="font-body-semibold-small">{partnerResponsibleName}</p>
				)}
			</div>
			<div className="profile-options">
				<Button
					styled="ghost"
					onClick={handleLogout}
					disabled={!partnerResponsibleName}
					cssClass="logout"
					size="small"
				>
					Sair da conta
				</Button>
			</div>
		</div>
	);
}
