import { WorkingHoursData } from "components/PartnerForms/steps/types";
import { TypeStatusTag } from "components/StatusTag";
import { convertWorkingHoursArrayToWorkingHoursData } from "utils";
import { BaseModel } from "./BaseModel";
import { Category } from "./Category";
import { PartnerAddress } from "./PartnerAddress";
import {
	PartnerAddressProps,
	PartnerInterface,
	StatusRegisterTypes
} from "./types";

export class Partner extends BaseModel {
	id?: string;
	name: string;
	cnpj: string;
	contactFacebook: string;
	contactInstagram: string;
	contactPhone: string;
	contactWhatsapp: string;
	description: string;
	legalName: string;
	logoImage: string;
	establishmentImage: string;
	tempRegistrationIsCompleted: boolean;
	responsibleEmail: string;
	responsibleName: string;
	responsiblePhone: string;
	responsibleCpf: string;
	responsibleRole: string;
	siteUrl: string;
	status?: string;
	statusRegister?: string;
	partnerRegisterId?: string;
	tenantId: string;
	term: string;
	tradeName: string;
	ie: string;
	remark: string;
	categoryIds?: string[];
	categories?: Category[];
	address?: PartnerAddressProps;
	addresses?: PartnerAddress[];
	workingHours: WorkingHoursData;
	prospectId?: string;
	validationHash?: string;

	constructor(data: PartnerInterface) {
		super(data);
		this.id = data?.id;
		this.name = data.name;
		this.responsibleName = data.responsibleName;
		this.responsiblePhone = data.responsiblePhone;
		this.responsibleEmail = data.responsibleEmail;
		this.responsibleCpf = data.responsibleCpf || "";
		this.responsibleRole = data.responsibleRole || "";
		this.cnpj = data.cnpj;
		this.contactFacebook = data.contactFacebook;
		this.contactInstagram = data.contactInstagram;
		this.contactPhone = data.contactPhone;
		this.contactWhatsapp = data.contactWhatsapp;
		this.description = data.description;
		this.legalName = data.legalName;
		this.logoImage = data.logoImage;
		this.establishmentImage = data.establishmentImage;
		this.siteUrl = data.siteUrl;
		this.status = data.status;
		this.statusRegister = data.statusRegister;
		this.partnerRegisterId = data.partnerRegisterId;
		this.tempRegistrationIsCompleted = data.tempRegistrationIsCompleted;
		this.tenantId = data.tenantId;
		this.term = data.term;
		this.tradeName = data.tradeName;
		this.ie = data.ie;
		this.remark = data.remark;
		this.categoryIds = data.categoryIds;
		this.categories = data?.categories?.map((branch) =>
			Category.getValueOrNew(branch)
		);
		this.address = data?.addresses?.[0];
		this.addresses = data?.addresses?.map((branch) =>
			PartnerAddress.getValueOrNew(branch)
		);
		this.workingHours = data.workingHours;
		this.prospectId = data?.prospectId;
	}

	static createFromResponse(response: any) {
		if (!response) {
			return response;
		}

		return new Partner({
			...response,
			workingHours: response.workingHours
				? convertWorkingHoursArrayToWorkingHoursData(response.workingHours)
				: undefined
		});
	}

	static createFromProspectResponse(response: any) {
		if (!response) {
			return response;
		}

		return new Partner({
			...response,
			name: response.tradeName ?? "",
			addresses: [
				{
					postalCode: response.addressPostalCode ?? "",
					street: response.addressStreet ?? "",
					number: response.addressNumber ?? "",
					complement: response.addressComplement ?? "",
					neighbourhood: response.addressNeighbourhood ?? "",
					city: {
						id: "",
						name: response.addressCity,
						state: response.addressState,
						description: "",
						image: ""
					}
				}
			],
			categoryIds: [response.categoryId],
			categories: [
				{
					id: response.categoryId ?? "",
					name: ""
				}
			]
		});
	}

	get statusName() {
		switch (this.statusRegister) {
			case StatusRegisterTypes.deactivated:
				return "Desativado";
			case StatusRegisterTypes.approved:
				return "Ativo";
			default:
				return "Incompleto";
		}
	}

	get typeTagStatusList() {
		switch (this.statusRegister) {
			case StatusRegisterTypes.deactivated:
				return TypeStatusTag.INACTIVE;
			case StatusRegisterTypes.approved:
				return TypeStatusTag.ACTIVE;
			default:
				return TypeStatusTag.INCOMPLETE;
		}
	}

	get categoriesNames() {
		return this.categories?.map((category) => category.name).join("; ");
	}

	get addressesCities() {
		return this.addresses?.map((address) => address.city.name).join("; ");
	}

	get addressesStates() {
		return this.addresses?.map((address) => address.city.state).join("; ");
	}
}
