import SidebarPartnerSection from "components/SidebarPartnerSection";
import { PartnerProvider } from "hooks/usePartnerSectionContext";
import usePartnerSectionToast from "hooks/usePartnerSectionToast";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import "./styles.scss";

export default function PartnerSectionLayout() {
	const { showErrorToast } = usePartnerSectionToast();
	const { partnerRegisterId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (!partnerRegisterId) {
			showErrorToast("ID de parceiro não encontrado", "Erro");
			setTimeout(() => navigate("/"), 1000);
		}
	}, []);

	return (
		<PartnerProvider partnerRegisterId={partnerRegisterId!}>
			<div className="partner-section-layout">
				<SidebarPartnerSection />
				<Outlet />
			</div>
		</PartnerProvider>
	);
}
