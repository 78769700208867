import colors from "colors";
import { IconProps } from "./types";

export default function IcoGift({
	color = colors.neutral["low-pure-500"],
	size = "24"
}: IconProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox={`0 0 ${size} ${size}`}
			fill="none"
		>
			<path
				d="M4 7.61833H20V11.7456H4V7.61833Z"
				stroke={color}
				strokeLinejoin="round"
			/>
			<path
				d="M11.4531 7.081L12.7461 4.77025C13.3208 3.74325 14.7574 3.74325 15.3321 4.77025C15.9067 5.79725 15.1884 7.081 14.0391 7.081H11.4531Z"
				stroke={color}
				strokeLinejoin="round"
			/>
			<path
				d="M12.1719 7.081L10.8789 4.77025C10.3042 3.74325 8.86759 3.74325 8.29293 4.77025C7.71827 5.79725 8.4366 7.081 9.58592 7.081H12.1719Z"
				stroke={color}
				strokeLinejoin="round"
			/>
			<path
				d="M5 11.7456H19V20H5V11.7456Z"
				stroke={color}
				strokeLinejoin="round"
			/>
			<path
				d="M10 11.7456H14V20H10V11.7456Z"
				stroke={color}
				strokeLinejoin="round"
			/>
		</svg>
	);
}
