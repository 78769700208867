import { Constants } from "appConstants";
import { Loading, ModalRedirect } from "components";
import AuthTokenHelper from "helpers/AuthTokenHelper";
import { Partner } from "models/Partner";
import {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useState
} from "react";
import { useNavigate } from "react-router-dom";
import { ProspectService } from "services/prospect";

interface PartnerSectionContextProps {
	partnerDetails: Partner | undefined;
	setPartnerDetails: Dispatch<SetStateAction<Partner | undefined>>;
}

const PartnerSectionContext = createContext<
	PartnerSectionContextProps | undefined
>(undefined);

export const usePartnerSectionContext = () => {
	const context = useContext(PartnerSectionContext);
	if (!context) {
		throw new Error(
			"usePartnerSectionContext must be used within a PartnerProvider"
		);
	}
	return context;
};

interface PartnerSectionProviderProps {
	partnerRegisterId: string;
	children: ReactNode;
}

export function PartnerProvider({
	partnerRegisterId,
	children
}: PartnerSectionProviderProps) {
	const [partnerDetails, setPartnerDetails] = useState<Partner>();
	const [redirectModalOpen, setRedirectModalOpen] = useState(false);
	const prospectService = new ProspectService();

	const navigate = useNavigate();

	useEffect(() => {
		prospectService
			.getUserPartnerDetails(partnerRegisterId)
			.then((response) => {
				setPartnerDetails(Partner.createFromResponse(response));
			})
			.catch((error) => {
				const errorCode = error.response?.status;
				if (errorCode === 400) {
					setRedirectModalOpen(true);
					AuthTokenHelper.removeAuthToken();
					setTimeout(() => {
						navigate("/");
					}, 3000);
				}
			});
	}, [partnerRegisterId]);

	const value = useMemo(
		() => ({ partnerDetails, setPartnerDetails }),
		[partnerDetails]
	);

	return (
		<>
			{partnerDetails ? (
				<PartnerSectionContext.Provider value={value}>
					{children}
				</PartnerSectionContext.Provider>
			) : (
				<div className="flex flex-1 justify-center items-center h-screen">
					<Loading size={64} type="primary" />
				</div>
			)}
			{redirectModalOpen && (
				<ModalRedirect
					isOpen={redirectModalOpen}
					text={Constants.textRedirectExpiredSession}
				/>
			)}
		</>
	);
}
