import "./styles.scss";

export enum TypeStatusTag {
	ACTIVE = "active",
	INACTIVE = "inactive",
	INCOMPLETE = "incomplete"
}

type StatusTagProps = {
	text: string;
	type: TypeStatusTag;
};
export default function StatusTag({ text, type }: StatusTagProps) {
	return <span className={`status-tag ${type}`}>{text}</span>;
}
