import { Constants } from "appConstants";
import { WorkingHoursData } from "components/PartnerForms/steps/types";
import { baseConfig } from "config";
import { WorkingHoursProps } from "models/types";

export const formatter = new Intl.NumberFormat("pt-BR", {
	style: "currency",
	currency: "BRL"
});

export const formattedPhoneNumber = (phone: string) => {
	if (phone.length === 10) {
		return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)}-${phone.slice(6)}`;
	}
	return `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7)}`;
};

export const formattedPhoneNumber0800 = (phone: string) => {
	return `${phone.slice(0, 4)}-${phone.slice(4, 7)}-${phone.slice(7)}`;
};

export const convertDataTreeToBoolTree = (
	data: object | Array<unknown>
): object | Array<unknown> => {
	if (Array.isArray(data))
		return data.map((item) =>
			!!item && typeof item === "object"
				? convertDataTreeToBoolTree(item)
				: !!item
		);
	return Object.fromEntries(
		Object.entries(data).map(([key, value]) => [
			key,
			!!value && typeof value === "object"
				? convertDataTreeToBoolTree(value)
				: !!value
		])
	);
};

export const getUfByStateName = (stateName: string | undefined) => {
	return Object.keys(Constants.FederalUnitsRecord).find(
		(key: string) => (Constants.FederalUnitsRecord as any)?.[key] === stateName
	);
};

export function convertWorkingHoursArrayToWorkingHoursData(
	workingHours: WorkingHoursProps[]
): WorkingHoursData {
	return Object.fromEntries(
		Object.keys(Constants.DaysLabels).map((day) => {
			const dayData = workingHours.find((wh) => wh.dayOfWeek === day);
			return [
				day,
				{
					opens: !!dayData,
					startHour: dayData?.startHour || "",
					endHour: dayData?.endHour || ""
				}
			];
		})
	) as unknown as WorkingHoursData;
}

export function convertWorkingHoursDataToWorkingHoursArray(
	workingHours: WorkingHoursData
): WorkingHoursProps[] {
	return Object.entries(workingHours)
		.filter(([, value]) => !!value.opens)
		.map(([key, value]) => {
			return {
				dayOfWeek: key,
				startHour: value.startHour,
				endHour: value.endHour
			};
		});
}

export function getImageNameFromFullUrl(imageUrl: string): string | null {
	const splitString = !imageUrl ? null : imageUrl.split("/");
	const imageName = splitString?.pop();
	return imageName ?? "";
}

export function getB2cLoginUrl(): string {
	const client_id = baseConfig.b2cClientId;
	const redirect = `${window.location.protocol}//${window.location.host}`;
	const b2c_url = baseConfig.b2cUrl;
	const loginURL = `${b2c_url}/oauth2/v2.0/authorize?p=B2C_1_SUSI&client_id=${client_id}&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login&redirect_uri=${redirect}/confirm-login`;

	return loginURL;
}
